<template>
  <div
    class="page-instructions-text"
    v-if="cartDetails.product_id === 268 || cartDetails.product_id === 282"
  >
    <h4>P1 General Parking</h4>
    <p>Your parking reservation is in <b>P1 General.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 8 ft. 2 in.</b>
      </li>
      <li v-if="cartDetails.product_id === 282">
        EV charging not included. Please download the PowerFlex app to start
        your EV charging session.
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>P1 General</b> reservation in P1 is located at 1d Center Way, Los Angeles, CA 90045 (P1). Use the upper-level entrance (departures) or the
      <span style="background: yellow">second entrance on the lower level</span>
      (arrivals). If the entry gate does not automatically open, scan your QR
      Code.
    </p>
    <p>
      <b>Please note:</b> Your reservation is not activated for the first
      entrance on the lower level, which is for premium parking only. This
      garage’s vertical clearance is 8 ft. 2 in. If your vehicle exceeds this
      height, you will be relocated to another location.
    </p>
    <p>
      <a href="https://goo.gl/maps/vVvhSm82MeYTRY749" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div
    class="page-instructions-text"
    v-if="cartDetails.product_id === 269 || cartDetails.product_id === 270"
  >
    <h4>P1 Premium Parking</h4>
    <p>Your reservation is in <b>P1 Premium Parking.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 8 ft. 2 in.</b>
      </li>
      <li v-if="cartDetails.product_id === 270">
        EV charging not included. Please download the PowerFlex app to start
        your EV charging session.
      </li>
      <li>Reservation price does not include EV charging. Please download the PowerFlex app to start your EV charging session.</li>
      <li>We offer a 2-hour <bold>“Grace Period”</bold> to retrieve your vehicle after your paid exit time. After 2 hours, you will be subject
        to the drive-up day rate.</li>
      <li>Finally, if you incur any additional parking fees you can complete payment via the online payment portal. Parking rates
        are based on a 24-hour period beginning when the vehicle is dropped off.</li>
      <li>For any additional questions or concerns regarding this reservation, please contact <a href="mailto:laxreservations@abm.com">laxreservations@abm.com</a> or call
        310-646-2911.</li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>P1 Premium</b> reservation in P1 is located at 1d Center Way, Los Angeles, CA 90045 (P1). Use the first entrance
      <span style="background: yellow">lower level only (arrivals).</span> If the entry gate does not automatically open, scan your QR Code.
    </p>
    <p>
      <b>Please note:</b> This garage’s vertical clearance is 8 ft. 2 in. If
      your vehicle exceeds this height, you will be relocated to another
      location.
    </p>
    <p>
      <a href="https://goo.gl/maps/vVvhSm82MeYTRY749" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div
    class="page-instructions-text"
    v-if="cartDetails.product_id === 271 || cartDetails.product_id === 272"
  >
    <h4>P2A General Parking</h4>
    <p>Your reservation is in <b>P2A General Parking.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 8 ft. 2 in.</b>
      </li>
      <li v-if="cartDetails.product_id === 272">
        EV charging not included. Please download the PowerFlex app to start
        your EV charging session.
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>P2A General</b> reservation in P2A is located at 1 World Way, Los
      Angeles, CA 90045.
      <span style="background: yellow"
        >Please use the lower level (ARRIVALS)</span
      >
      to access the garage. If the entry gate does not automatically open, scan
      your QR Code.
    </p>
    <p>
      <b>Please note:</b> This garage’s vertical clearance is 8 ft. 2 in. If
      your vehicle exceeds this height, you will be relocated to another
      location.
    </p>
    <p>
      <a href="https://maps.app.goo.gl/5ckuvpmNtquy9vhi6" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div
    class="page-instructions-text"
    v-if="cartDetails.product_id === 273 || cartDetails.product_id === 275"
  >
    <h4>P2B General Parking</h4>
    <p>Your reservation is in <b>P2B General Parking.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 7 ft.</b>
      </li>
      <li v-if="cartDetails.product_id === 275">
        EV charging not included. Please download the PowerFlex app to start
        your EV charging session.
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>P2B General</b> reservation in P2B is located at 299 World Way, Los Angeles, CA 90045.
      <span style="background: yellow"
        >Please use the lower level (ARRIVALS)</span
      >
      to access the garage. If the entry gate does not automatically open, scan
      your QR Code.
    </p>
    <p>
      <b>Please note:</b> This garage’s vertical clearance is 7 ft. If your
      vehicle exceeds this height, you will be relocated to another location.
    </p>
    <p>
      <a href="https://maps.app.goo.gl/YsPCqWr4Js8969zx9" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div
    class="page-instructions-text"
    v-if="
      cartDetails.product_id === 274 ||
      cartDetails.product_id === 278 ||
      cartDetails.product_id === 277 ||
      cartDetails.product_id === 276
    "
  >
    <h4>{{ cartDetails.product_display_name }} Parking</h4>
    <p>
      Your reservation is in
      <b>{{ cartDetails.product_display_name }} Parking</b>
    </p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 7 ft. 6 in.</b>
      </li>
      <li
        v-if="cartDetails.product_id === 278 || cartDetails.product_id === 276"
      >
        EV charging not included. Please download the PowerFlex app to start
        your EV charging session.
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>{{ cartDetails.product_display_name }} Parking</b> reservation in
      <b>{{ cartDetails.carpark_name }}</b> is located at 
      <span v-if="cartDetails.product_id === 277 || cartDetails.product_id === 276">1 World Way, Los Angeles, CA 90045  - WHVV+66 Los Angeles, California</span>
      <span v-if="cartDetails.product_id === 278">401 World Way, Los Angeles, CA 90045.</span>
      <span v-else>301 World Way, Los Angeles, CA 90045.</span>
      <span style="background: yellow"
        >Please use the lower level (ARRIVALS) to access the garage.</span
      >
      If the entry gate does not automatically open, scan your QR Code.
    </p>
    <p>
      <b>Please note:</b> This garage’s vertical clearance is 7 ft. 6 in. If
      your vehicle exceeds this height, you will be relocated to another
      location.
    </p>
    <p>
      <a href="https://maps.app.goo.gl/92M6kMnYy2J9QCF97" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div
    class="page-instructions-text"
    v-if="cartDetails.product_id === 279 || cartDetails.product_id === 280"
  >
    <h4>P5 General Parking</h4>
    <p>Your parking reservation is in <b>P5 General.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 7 ft.</b>
      </li>
      <li v-if="cartDetails.product_id === 280">
        EV charging not included. Please download the PowerFlex app to start
        your EV charging session.
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>P5 General</b> parking reservation in P5 is located at 1 World
      Way, Los Angeles, CA 90045. Please use the upper-level entrance
      (departures) or the
      <span style="background: yellow"
        >second entrance on the lower level (arrivals).</span
      >
      If the entry gate does not open automatically, scan your QR Code.
    </p>
    <p>
      <b>Please Note:</b> This garage’s vertical clearance is 7 ft. If your
      vehicle exceeds this height, you will be relocated to another location.
    </p>
    <p>
      <a href="https://goo.gl/maps/vVvhSm82MeYTRY749" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div
    class="page-instructions-text"
    v-if="cartDetails.product_id === 281 || cartDetails.product_id === 283"
  >
    <h4>P6 General Parking</h4>
    <p>Your parking reservation is in <b>P6 General.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 8 ft. 2 in.</b>
      </li>
      <li v-if="cartDetails.product_id === 283">
        EV charging not included. Please download the PowerFlex app to start
        your EV charging session.
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>P6 General</b> parking reservation in P6 is located at 1 World
      Way, Los Angeles, CA 90045. Please use the upper-level entrance
      (departures) or the
      <span style="background: yellow"
        >second entrance on the lower level (arrivals).</span
      >
    </p>
    <p>
      <b>Please note:</b> This garage’s vertical clearance is 8 ft. 2 in. If
      your vehicle exceeds this height, you will be relocated to another
      location.
    </p>
    <p>
      <a href="https://maps.app.goo.gl/jTvVEeXPEusCgSGp6" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 254">
    <h4>Economy Parking</h4>
    <p>Your parking reservation is in <b>Economy Parking.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>
        Please note that only level 1 has a vertical height clearance of 14 ft.
        If your vehicle is oversized, it will not fit on levels 2-4 with height
        clearance of 8 ft. 2 in.
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Economy Parking is located at 6100 W 94th St, Los Angeles, CA, 90045.<br /><br />
      <a href="https://goo.gl/maps/inM4vVs1qG4jHVgi7" target="_blank"
        >Click here</a
      >
      for driving directions.
    </p>
    <p>
      LAX Economy Parking Shuttles operate 24/7 approximately every 10 minutes
      to and from the Economy Parking facility, where pickup is located on the
      southwest corner of the <b>ground level</b>. Follow the signs throughout
      the lot. Shuttles drop off passengers at all terminals on the ground level
      (arrivals) and guests should go up to level 2 (departures) for their
      check-in and security.
      <b
        >Upon return, wait for the Economy Parking Shuttles at the closest pink
        sign, located on the inside curb of the ground level (arrivals).</b
      >
      <a
        href="http://www.flylax.com/-/media/flylax/ground-transportation/ground-transportation-waiting-areas.ashx"
        target="_blank"
      >
        Click here</a
      >
      for a map of terminal pickup locations. For shuttle related questions,
      call (310) 486-8334.
    </p>
    <p>Please drive safely and have a great trip.</p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 256">
    <h4>Economy EV Parking</h4>
    <p>Your parking reservation is in <b>Economy Parking.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button. Remember to download the
      PowerFlex app beforehand so you can start your EV charging session when
      you arrive.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 8 ft. 2 in.</b> If your vehicle exceeds
        this height, you may only park on level 1 with clearance of 14 ft.
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        EV charging not included. Please download the PowerFlex app to start
        your EV charging session.
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Economy Parking is located at 6100 W 94th St, Los Angeles, CA, 90045.<br /><br />
      <a href="https://goo.gl/maps/inM4vVs1qG4jHVgi7" target="_blank"
        >Click here</a
      >
      for driving directions.
    </p>
    <p>
      LAX Economy Parking Shuttles operate 24/7 approximately every 10 minutes
      to and from the Economy Parking facility, where pickup is located on the
      southwest corner of the <b>ground level</b>. Follow the signs throughout
      the lot. Shuttles drop off passengers at all terminals on the ground level
      (arrivals) and guests should go up to level 2 (departures) for their
      check-in and security.
      <b
        >Upon return, wait for the Economy Parking Shuttles at the closest pink
        sign, located on the inside curb of the ground level (arrivals).</b
      >
      <a
        href="http://www.flylax.com/-/media/flylax/ground-transportation/ground-transportation-waiting-areas.ashx"
        target="_blank"
      >
        Click here</a
      >
      for a map of terminal pickup locations. For shuttle related questions,
      call (310) 486-8334.
    </p>
    <p>Please drive safely and have a great trip.</p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 257">
    <h4>Van Nuys FlyAway</h4>
    <p>Your reservation is at <b>Van Nuys FlyAway.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        Scan the QR code shown on this email or from your wallet app on your
        phone on the QR reader
      </li>
      <li><b>DO NOT PULL A TICKET</b></li>
      <li>If you have any issues, please call <b>(310) 242-4676.</b></li>
    </ol>
    <p>Please drive safely and have a great trip.</p>
    <h4>How To Get There</h4>
    <p>
      Van Nuys Parking is located at 7610 Woodley Ave #164, Van Nuys, CA 91406 (Van Nuys Flyaway Bus Terminal Parking).
      <a href="https://maps.app.goo.gl/RCdG6LtcPk8T5Mm97" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 258">
    <h4>P7 General Parking</h4>
    <p>Your parking reservation is in <b>P7 General.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 7 ft.</b>
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>P7 General</b> parking reservation in P7 is located at 701 World Way, Los Angeles, CA 90045 (P7). To enter, scan your QR code at the
      <span style="background: yellow"
        >entrance on the lower level (arrivals).</span
      >
    </p>
    <p>
      <b>Please note:</b> This garage’s vertical clearance is 7 ft. If your
      vehicle exceeds this height, you will be relocated to another location.
    </p>
    <p>
      <a href="https://maps.app.goo.gl/ntQzzdRd94C7tPVw7" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 259">
    <h4>Budget Parking</h4>
    <p>Your reservation is in <b>Budget Parking.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        Scan the QR code shown on this email or from your wallet app on your
        phone on the QR reader
      </li>
      <li><b>DO NOT PULL A TICKET</b></li>
      <li>If you have any issues, please call <b>(213) 457-7796.</b></li>
    </ol>
    <p>Please drive safely and have a great trip.</p>

    <h4>How To Get There</h4>
    <p>
      <b>Budget Parking</b> is located at 5455 W 111th St, Los Angeles, CA
      90045. For directions
      <a href="https://maps.app.goo.gl/p84Fp36tHnR4fkLu9" target="_blank"
        >Click here</a
      >
    </p>
    <p>
      LAX Budget Parking Shuttles operate 24/7 approximately every 20-25 minutes
      to and from the Budget Parking facility. Pickup is located throughout the
      lot at the blue shuttle stops. Shuttle stops are marked by a blue awning.
    </p>
    <p>
      Shuttles drop off passengers at all terminals on the ground level
      (arrivals) and guests should go up to level 2 (departures) for their
      check-in and security.
      <b
        >Upon return, wait for the Budget Parking Shuttles at the closest pink
        sign, located on the inside curb of the ground level (arrivals)</b
      >.
      <a
        href="https://www.flylax.com/-/media/flylax/ground-transportation/ground-transportation-waiting-areas.ashx"
        target="_blank"
        >Click here</a
      >
      for a map of terminal pickup locations. For shuttle related questions,
      call (213) 457-7796.
    </p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 260">
    <h4>P1 Valet Parking</h4>
    <p>Your reservation is in <b>P1 Valet Parking.</b></p>
    <p>
      We look forward to welcoming you to LAX Airport. Please quote this
      reference at the airport should you require assistance.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The P1 Valet Plaza is located on the
        <b>LOWER LEVEL (Arrivals level)</b> of the Airport from World Way Drive.
      </li>
      <li>
        Please note this garage’s vertical clearance is <b>8 ft. 2 in.</b> If
        your vehicle exceeds this height, you will be relocated to another
        location.
      </li>
      <li>
        As you drive up to the P1 garage, you will utilize the first entrance
        way that has the Valet overhead sign.
      </li>
      <li>
        Upon entering the Valet Plaza, you will be greeted by an airport valet
        attendant who will ask you for your contact number, returning airline
        information, and returning arrival date.
      </li>
      <li>
        <b
          >Please have your QR code or Reservation number ready to present to
          the attendant.</b
        >
      </li>
      <li>
        <b
          >All vehicles must present the valet attendant with the physical key
          (or key with fob) upon arrival.</b
        >
      </li>
      <li>
        Upon return you will retrieve your vehicle from the valet plaza.
        Although curbside service is <b>NOT</b> available, we offer a convenient
        “Text Ahead” feature.
      </li>
      <li>
        For expedited retrieval, text only your ticket number to the
        <b>“Text Ahead”</b> number on your valet slip (reference image below).
        Only submit the Ticket Number via text message.
        <div style="text-align: center; margin-top: 0.7rem">
          <img
            src="https://res.cloudinary.com/rezcomm2018/image/upload/v1734940076/brands/LAX/P1_valet_exit_check.png"
            alt="P1 Valet Exit Check"
          />
        </div>
      </li>
      <li>
        We offer a 2-hour <b>"Grace Period"</b> to retrieve your vehicle after
        your paid exit time. After 2 hours, you will be subject to the drive-up
        rate of $75 per day.
      </li>
      <li>
        Finally, if you incur any additional parking fees, you can complete
        payment via the online payment portal. Parking rates are based on a
        24-hour period beginning when the vehicle is dropped off in the Valet
        Plaza.
      </li>
      <li>
        For any additional questions or concerns regarding this reservation,
        please contact:
        <a href="mailto:laxreservations@abm.com">laxreservations@abm.com</a> or
        call <b>310-646-2911</b>.
      </li>
    </ol>

    <p>Please drive safely and have a great trip.</p>

    <h4>How To Get There</h4>
    <p>
      <b>P1</b> is located at 1d Center Way, Los Angeles, CA 90045.
      <b>P1 Valet Plaza</b> is located ONLY on LOWER LEVEL (ARRIVALS LEVEL) from
      WORLD WAY DRIVE.
    </p>
    <p>
      For directions click here, and for a map of the terminal
      <a href="htts://goo.gl/maps/vVvhSm82MeYTRY749" target="_blank"
        >click here.</a
      >
    </p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 261">
    <h4>P3 Valet Parking</h4>
    <p>Your reservation is in <b>P3 Valet Parking.</b></p>
    <p>
      We look forward to welcoming you to LAX Airport. Please quote this
      reference at the airport should you require assistance.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The P3 Valet Plaza is located on the
        <b>LOWER LEVEL (Arrivals level)</b> of the Airport from World Way Drive.
      </li>
      <li>
        Please note this garage’s vertical clearance is <b>7 ft. 6 in.</b> If
        your vehicle exceeds this height, you will be relocated to another
        location.
      </li>
      <li>
        As you drive up to the P3 garage, you will utilize the second entrance
        way following the Valet Signage.
      </li>
      <li>
        Upon entering the Valet Plaza, you will be greeted by an airport valet
        attendant who will ask you for your contact number, returning airline
        information, and returning arrival date.
      </li>
      <li>
        <b
          >Please have your QR code or Reservation number ready to present to
          the attendant.</b
        >
      </li>
      <li>
        <b
          >All vehicles must present the valet attendant with the physical key
          (or key with fob) upon arrival.</b
        >
      </li>
      <li>
        Upon return you will retrieve your vehicle from the valet plaza.
        Although curbside service is <b>NOT</b> available, we offer a convenient
        “Text Ahead” feature.
      </li>
      <li>
        For expedited retrieval, text only your ticket number to the
        <b>“Text Ahead”</b> number on your valet slip (reference image below).
        Only submit the Ticket Number via text message.
        <div style="text-align: center; margin-top: 0.7rem">
          <img
            src="https://res.cloudinary.com/rezcomm2018/image/upload/v1734940077/brands/LAX/P3_and_P4_valet_exit_check.jpg"
            alt="P3 Valet Exit Check"
            style="height: 150px"
          />
        </div>
      </li>
      <li>
        We offer a 2-hour <b>"Grace Period"</b> to retrieve your vehicle after
        your paid exit time. After 2 hours, you will be subject to the drive-up
        rate of $75 per day.
      </li>
      <li>
        Finally, if you incur any additional parking fees you can complete
        payment via the online payment portal. Parking rates are based on a
        24-hour period beginning when the vehicle is dropped off in the Valet
        Plaza.
      </li>
      <li>
        For any additional questions or concerns regarding this reservation,
        please contact:
        <a href="mailto:laxreservations@abm.com">laxreservations@abm.com</a> or
        call <b>310-646-2911</b>.
      </li>
    </ol>

    <p>Please drive safely and have a great trip.</p>

    <h4>How To Get There</h4>
    <p>
      <b>P3</b> is located at 301 World Way, Los Angeles, CA 90045.
      <b>P3 Valet Plaza</b> is located ONLY on LOWER LEVEL (ARRIVALS LEVEL) from
      WORLD WAY DRIVE.
    </p>
    <p>
      For directions click here, and for a map of the terminal
      <a href="https://goo.gl/maps/vVvhSm82MeYTRY749" target="_blank"
        >click here.</a
      >
    </p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 262">
    <h4>P4 Valet Parking</h4>
    <p>Your reservation is in <b>P4 Valet Parking.</b></p>
    <p>
      We look forward to welcoming you to LAX Airport. Please quote this
      reference at the airport should you require assistance.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The P4 Valet Plaza is located on the
        <b>LOWER LEVEL (Arrivals level)</b> of the Airport from World Way Drive.
      </li>
      <li>
        Please note this garage’s vertical clearance is <b>7 ft. 6 in.</b> If
        your vehicle exceeds this height, you will be relocated to another
        location.
      </li>
      <li>
        As you drive up to the P4 garage, you will utilize the first driveway,
        second lane to access valet. Please pull a ticket at the entry gate as
        the Valet attendant will collect this.
      </li>
      <li>
        Upon entering the Valet Plaza, you will be greeted by an airport valet
        attendant who will ask you for your contact number, returning airline
        information, and returning arrival date.
      </li>
      <li>
        <b
          >Please have your QR code or Reservation number ready to present to
          the attendant.</b
        >
      </li>
      <li>
        <b
          >All vehicles must present the valet attendant with the physical key
          (or key with fob) upon arrival.</b
        >
      </li>
      <li>
        Upon return you will retrieve your vehicle from the valet plaza.
        Although curbside service is <b>NOT</b> available, we offer a convenient
        “Text Ahead” feature.
      </li>
      <li>
        For expedited retrieval, text only your ticket number to the
        <b>“Text Ahead”</b> number on your valet slip (reference image below).
        Only submit the Ticket Number via text message.
        <div style="text-align: center; margin-top: 0.7rem">
          <img
            src="https://res.cloudinary.com/rezcomm2018/image/upload/v1734940077/brands/LAX/P3_and_P4_valet_exit_check.jpg"
            alt="P3 Valet Exit Check"
            style="height: 150px"
          />
        </div>
      </li>
      <li>
        We offer a 2-hour <b>"Grace Period"</b> to retrieve your vehicle after
        your paid exit time. After 2 hours, you will be subject to the drive-up
        rate of $75 per day.
      </li>
      <li>
        Finally, if you incur any additional parking fees you can complete
        payment via the online payment portal. Parking rates are based on a
        24-hour period beginning when the vehicle is dropped off in the Valet
        Plaza.
      </li>
      <li>
        For any additional questions or concerns regarding this reservation,
        please contact:
        <a href="mailto:laxreservations@abm.com">laxreservations@abm.com</a> or
        call <b>310-646-2911</b>.
      </li>
    </ol>

    <p>Please drive safely and have a great trip.</p>

    <h4>How To Get There</h4>
    <p>
      <b>P4</b> is located at 1 World Way, Los Angeles, CA 90045.
      <b>P4 Valet Plaza</b> is located ONLY on LOWER LEVEL (ARRIVALS LEVEL) from
      WORLD WAY DRIVE.
    </p>
    <p>
      For directions click here, and for a map of the terminal
      <a href="https://goo.gl/maps/vVvhSm82MeYTRY749" target="_blank"
        >click here.</a
      >
    </p>
  </div>

  <div class="page-instructions-text" v-if="cartDetails.product_id === 263">
    <h4>P7 Valet Parking</h4>
    <p>Your reservation is in <b>P7 Valet Parking.</b></p>
    <p>
      We look forward to welcoming you to LAX Airport. Please quote this
      reference at the airport should you require assistance.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The P7 Valet Plaza is located on the
        <b>LOWER LEVEL (Arrivals level)</b> of the Airport from World Way Drive.
      </li>
      <li>
        Please note this garage’s vertical clearance is <b>7 ft.</b> If your
        vehicle exceeds this height, you will be relocated to another location.
      </li>
      <li>
        As you drive up to the P7 garage, you will utilize the last driveway,
        first lane to access valet.
      </li>
      <li>
        Upon entering the Valet Plaza, you will be greeted by an airport valet
        attendant who will ask you for your contact number, returning airline
        information, and returning arrival date.
      </li>
      <li>
        <b
          >Please have your QR code or Reservation number ready to present to
          the attendant.</b
        >
      </li>
      <li>
        <b
          >All vehicles must present the valet attendant with the physical key
          (or key with fob) upon arrival.</b
        >
      </li>
      <li>
        Upon return you will retrieve your vehicle from the valet plaza.
        Although curbside service is <b>NOT</b> available, we offer a convenient
        “Text Ahead” feature.
      </li>
      <li>
        For expedited retrieval, text only your ticket number to the
        <b>“Text Ahead”</b> number on your valet slip (reference image below).
        Only submit the Ticket Number via text message.
        <div style="text-align: center; margin-top: 0.7rem">
          <img
            src="https://res.cloudinary.com/rezcomm2018/image/upload/v1734940077/brands/LAX/P7_valet_exit_check.png"
            alt="P7 Exit Check"
            style="height: 150px"
          />
        </div>
      </li>
      <li>
        We offer a 2-hour <b>"Grace Period"</b> to retrieve your vehicle after
        your paid exit time. After 2 hours, you will be subject to the drive-up
        rate of $75 per day.
      </li>
      <li>
        Finally, if you incur any additional parking fees you can complete
        payment via the online payment portal. Parking rates are based on a
        24-hour period beginning when the vehicle is dropped off in the Valet
        Plaza.
      </li>
      <li>
        For any additional questions or concerns regarding this reservation,
        please contact:
        <a href="mailto:laxreservations@abm.com">laxreservations@abm.com</a> or
        call <b>310-646-2911</b>.
      </li>
    </ol>

    <p>Please drive safely and have a great trip.</p>

    <h4>How To Get There</h4>
    <p>
      <b>P7</b> is located at 701 World Way, Los Angeles, CA 90045 (P7).
      <b>P7 Valet Plaza</b> is located ONLY on LOWER LEVEL (ARRIVALS LEVEL) from
      WORLD WAY DRIVE.
    </p>
    <p>
      For directions click here the button below, and for a map of the terminal
      <a href="https://maps.app.goo.gl/ntQzzdRd94C7tPVw7" target="_blank"
        >click here.</a
      >
    </p>
  </div>

  <div
    class="page-instructions-text"
    v-if="cartDetails.product_id === 295"
  >
    <h4>P7 EV Parking</h4>
    <p>Your reservation is in <b>P7 EV.</b></p>
    <p>
      To start your journey, simply scan your QR code at the barrier. If you
      have any issues, press the “Help” button.
    </p>
    <h4>Instructions</h4>
    <ol>
      <li>
        The barrier should automatically go up as it will recognize your license
        plate
      </li>
      <li>
        If the gate does not go up, scan the QR code shown on this email on the
        QR reader
      </li>
      <li>DO NOT PULL A TICKET</li>
      <li>Any issues press the HELP button</li>
      <li>
        Please note that this garage has a
        <b>vertical height clearance of 7 ft.</b>
      </li>
      <li>
        EV charging not included. Please download the PowerFlex app to start your EV charging session.
      </li>
    </ol>
    <h4>How To Get There</h4>
    <p>
      Your <b>P7 EV</b> reservation in P7 is located at 701 World Way, Los Angeles, CA 90045 (P7). To enter, scan your QR code at the entrance on the lower level (arrivals).
    </p>
    <p>
      <b>Please note:</b> This garage’s vertical clearance is 7 ft. If your vehicle exceeds this height, you will be relocated to another location.
    </p>
    <p>
      <a href="https://maps.app.goo.gl/ntQzzdRd94C7tPVw7" target="_blank"
        >Click here</a
      >
      for Driving Directions.
    </p>
  </div>

  <!--  -->
</template>

<script>
export default {
  name: "TicketInstructions",
  props: {
    ordercomplete: {
      type: Boolean,
      required: true,
    },
    brandSlug: {
      type: String,
      required: true,
    },
    cartDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";

.page-instructions-text {
  padding: 2rem 1rem;
  text-align: left;
  h4 {
    font-size: 1rem;
    margin-bottom: 0px;
  }
  h3 {
    font-size: 1.15rem;
    margin-bottom: 0px;
  }
  p {
    font-size: 12px;
    line-height: 1.5;
  }
  a {
    color: #3f96f3;
  }
  b {
    font-weight: 700;
    color: black;
  }
}

.underline {
  text-decoration-line: underline;
}

p {
  color: $page-text;
}
a {
  color: $page-links;
  &:hover {
    color: darken($page-links, 10%);
  }
}
</style>
